<template>
  <div>
    <v-text-field
      outline
      clearable
      hide-details
      single-line
      class="search"
      prepend-inner-icon="search"
      :placeholder="placeholder"
      id="search"
      autocomplete="off"
      v-model.trim="query"
      @click:clear="clearSearch"
      @keydown.esc="clearResults"
      @keydown.enter="executeSearch"
      @input="lazySearch"
      @focus="focusInput()"
    />
    <v-progress-circular
      v-if="isSearching"
      indeterminate
      class="ma-auto loading-indicator"
      :size="20"
      :width="3"
    />
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'SSearchField',
  props: {
    isSearching: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCancelled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Search',
    },
  },
  data () {
    return {
      query: '',
    }
  },
  methods: {
    clearSearch () {
      this.query = ''
      this.$emit('clear')
    },
    clearResults () {
      this.$emit('clear')
    },
    focusInput () {
      this.$emit('focused')
    },
    lazySearch: _.debounce(
      function () {
        this.trySearch()
      },
      500,
      { leading: false }
    ),
    trySearch () {
      if (!this.query) {
        this.clearSearch()
        return
      }
      if (this.query.trim().length < 3) {
        return
      }
      this.executeSearch()
    },
    executeSearch () {
      if (this.query.trim()) {
        this.$emit('query', this.query)
      }
    },
  },
  watch: {
    isCancelled: {
      handler (newValue, oldValue) {
        if (newValue === oldValue) return
        if (this.isCancelled !== true) return
        if (this.query === '') return
        this.clearSearch()
      },
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
:deep(.v-input__slot) {
  background: transparent !important;
  border: $borderline;
  border-radius: $default-radius;
  min-height: $input-height;
  height: $input-height;
  padding-left: 10px;
  padding-right: 10px;
}

:deep(.v-input__slot:hover) {
  border-color: var(--v-accent-light);
}

:deep(.v-input__slot::after),
:deep(.v-input__slot::before) {
  display: none !important;
}

:deep(.v-label) {
  font-weight: $font-medium;
  font-size: $text-md !important;
  transform: translateY(-5px);
}

:deep(.v-label--active) {
  transform: translateY(-140%) scale(0.9);
  background: white;
}

:deep(input) {
  height: 32px;
  font-weight: $font-medium;
  font-size: $text-md !important;
}

:deep(.v-input__append-inner),
:deep(.v-input__prepend-inner) {
  margin-top: 10px;
}

.loading-indicator {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-20%);
}
</style>
