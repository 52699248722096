<template>
  <div class="s-search-bar">
    <s-search-field
      class="search-field"
      :placeholder="placeholder"
      :is-searching="isSearching"
      :is-cancelled="isCancelled"
      @focused="onFocused"
      @query="search"
      @clear="cancelSearch"
    />
    <s-search-bar-results-list
      class="search-results"
      :results="results"
      @click="cancelSearch"
      v-if="results && !hideResultList"
    />
  </div>
</template>
<script>
import SSearchBarResultsList from './SSearchBarResultsList'
import SSearchField from './SSearchField'
export default {
  name: 'SSearchBar',
  components: {
    's-search-field': SSearchField,
    's-search-bar-results-list': SSearchBarResultsList,
  },
  props: {
    placeholder: {
      type: String,
      required: false,
      default: 'Search',
    },
  },
  data () {
    return {
      query: '',
      results: null,
      isSearching: false,
      isCancelled: false,
      hideResultList: false,
    }
  },
  methods: {
    onFocused () {
      // allow show result list
      this.hideResultList = false
    },
    emptyResults () {
      this.results = null
    },
    cancelSearch () {
      // clear states, results
      this.isSearching = false
      this.isCancelled = true
      this.results = null
      this.query = ''
      this.onFocused()
      this.$emit('cleared')
    },
    notifyCompleteSearch (isEmpty = true) {
      this.$emit('completed', isEmpty)
    },
    notifyStartSearch () {
      this.$emit('started')
      this.emptyResults()
    },
    search (query) {
      // call search service, fetch matching records
      this.isSearching = true
      this.isCancelled = false
      this.query = query
      this.notifyStartSearch()
      this.$store
        .dispatch('user/search', {
          query: query,
        })
        .then(resp => {
          this.results = [resp]
          this.isSearching = false
          this.notifyCompleteSearch(false)
        })
        .catch(() => {
          this.results = []
          this.isSearching = false
          this.notifyCompleteSearch()
        })
    },
  },
}
</script>
<style lang="scss">
.s-search-bar {
  position: relative;
}
</style>
