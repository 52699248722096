<template>
  <v-row
    class="pa-0 ma-0 app-card align-center"
    @click="viewUser"
  >
    <v-col class="d-flex flex-column pa-2 px-3">
      <div class="d-flex align-center">
        <v-avatar class="mr-4" size="40">
          <v-img
            aspect-ratio="1"
            cover
            :lazy-src="require(`@/assets/avatars/user.svg`)"
            :src="userAvatarImage"
          />
        </v-avatar>
        <div class="align-center name-holder" style="width: 100%;">
          <s-text
            weight="medium"
            color="primary"
          >
            {{ user.bio.name }}
          </s-text>
          {{ user?.username }}
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'UserDetailsCard',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userAvatarImage () {
      return `${process.env.VUE_APP_API_DOMAIN}/v1/users/${this.user.user_id}/picture`
    },
  },
  methods: {
    viewUser () {
      this.$emit('click', this.user)
      this.$router.push({ name: 'appDetails', params: { id: this.user.user_id } })
    },
  },
}
</script>

<style lang="scss" scoped>
:deep {
  .v-skeleton-loader__card-heading {
    background: none !important;
  }

  .v-skeleton-loader__heading {
    margin: 0;
    height: 20px;
    width: 60%;
  }

  .name-holder {
    .v-skeleton-loader__heading {
      width: 100%;
    }
  }

  .v-image__image--preload {
    filter: none;
  }
}
</style>
